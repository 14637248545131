import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const showRoutes = [
  {
    path: "/layout",
    name: "layout",
    redirect: "/index",
    component: () => import("@/layout/index.vue"),
    meta: { title: "总览", index: 1 },
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@/views/home/index.vue"), // 总览
        meta: { title: "首页", index: 1 },
      },
      {
        path: "/detail",
        name: "detail",
        component: () => import("@/views/home/detail.vue"), // 总览
        meta: { title: "详情", auth: true },
      },
    ],
  },
];

const routes = [
  {
    path: "/",
    redirect: "/layout",
  },
  ...showRoutes,
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
  },
  { path: "*", redirect: "/", hidden: true },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.state.token;
  if (to.name !== "login" && !token) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
