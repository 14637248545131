import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueAMap from "@vuemap/vue-amap";
// import "@vuemap/vue-amap/dist/style.css";
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: "a13539c3c5dbec88092b9ce9d5a3a839",
//   version: "2.0",
//   Loca: {
//     version: "2.0",
//   },
// });

Vue.use(ElementUI);
Vue.component(CollapseTransition.name, CollapseTransition);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
